<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "OidcCallback",
  methods: {
    ...mapActions("oidcStore", ["oidcSignInCallback"]),
  },
  created() {
    this.oidcSignInCallback()
      .then((redirectPath) => this.$router.push(redirectPath))
      //.then(() => (window.location.href = window.location.origin))
      .catch(() => {
        this.$router.push({ name: "DashboardPadrao" });
      });
  },
};
</script>
